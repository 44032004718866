/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "~alertifyjs/build/css/alertify.min.css";
@import "~alertifyjs/build/css/themes/bootstrap.min.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";


.no-sort::after {
	display: none !important;
	background-image: none !important;
}

.no-sort {
	pointer-events: none !important;
	cursor: default !important;
	background-image: none !important;
}

.card .card-header .card-title {
	margin-bottom: -28px !important;
}

table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
	/*  cursor: pointer;
  *cursor: hand;
  background-repeat: no-repeat;*/
	background-position: center left !important;
}

a:hover {
	cursor: pointer !important;
}

span:hover {
	cursor: pointer !important;
}

table {
    width: 100%;
  }
